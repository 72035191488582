import React from 'react'
import './nav.css'
import {AiFillHome} from 'react-icons/ai'
import {HiMiniUserPlus} from 'react-icons/hi2'
import {FaBook} from 'react-icons/fa'
//import {RiServiceFill} from 'react-icons/ri'
import {FaFolderTree} from 'react-icons/fa6'
import {BiSolidMessageSquareEdit} from 'react-icons/bi'
import { useState } from 'react'
/*
i can't deside bitween these three for message 
so here they are to save them

BiSolidMessageRoundedEdit
BiSolidMessageAltEdit
BiSolidMessageSquareEdit
*/
/*

for projects

// FaDiagramProject
// FaFolderTree

*/



const Nav = () => {
  const [activeNav, setActiveNav] = useState( '#' )
  return (
    <nav>
      
      <a href="#" onClick={() => setActiveNav('#')}className={activeNav === '#' ? 'active':''}>
        <AiFillHome/>
      </a>
      <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}>
        <HiMiniUserPlus/>
      </a>
      <a href="#skills" onClick={() => setActiveNav('#skills')} className={activeNav === '#skills' ? 'active' : ''}>
        <FaBook/>
      </a>
      <a href="#projects" onClick={() => setActiveNav('#projects')} className={activeNav === '#projects' ? 'active' : ''}>
        <FaFolderTree/>
      </a>
      <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}>
        <BiSolidMessageSquareEdit/>
      </a>
    </nav>
  )
}

export default Nav