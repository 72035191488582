import React from 'react'
import './testimonials.css'
import Shehab from '../../assets/shehab.jpg'
import someone from '../../assets/someone.jpeg'

import {Swiper, SwiperSlide} from 'swiper/react';
import { EffectCards, Navigation, Pagination} from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const data =[
  {
    avatar: Shehab,
    name: 'Dr. PHD. Shehab',
    testimony: " placeholder "
  },
  {
    avatar: someone,
    name: 'never seen this guy',
    testimony: " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Exercitationem maiores illum rerum earum quos iusto iste odio tenetur magni ducimus eum deserunt expedita, ex illo officia unde saepe autem doloribus. sorry i threw u down "
  },
  {
    avatar: Shehab,
    name: 'Dr. PHD. Shehab',
    testimony: " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Exercitationem maiores illum rerum earum quos iusto iste odio tenetur magni ducimus eum deserunt expedita, ex illo officia unde saepe autem doloribus. sorry i threw u downLorem ipsum dolor, sit amet consectetur adipisicing elit. "
  }
]

const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h2>Testimonials</h2>
      <Swiper className="container testimonials_container"
      slidesPerView={1}
      spaceBetween={30}
      loop={true}
      pagination={{
      clickable: true,
      }}
      navigation={true}
      modules={[Pagination, Navigation]}
      
      >
        {
          data.map(({avatar, name, testimony}, index) =>{
            return(
              <SwiperSlide key={index} className='testimonial'>
                <div className='client_avatar'>
                  <img src={avatar} alt="Shehab" />
                  
                </div>
                <h3 className='client_name'>{name}</h3>
                <small className='client_testimony'>
                  {testimony}
                </small>
                {/* <a href="#contact" className='btn testimony_button'>Learn More</a>  for projects */}
              </SwiperSlide>
            )
          })
        }
        
      </Swiper>
    </section>
  )
}

export default Testimonials