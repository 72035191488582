import React from 'react'
import './projects.css'
import Shehab from '../../assets/shehab.jpg'
import openCV from '../../assets/opencv.png'
import Clumi from '../../assets/clumi.png'
import someone from '../../assets/someone.jpeg'
import AI from '../../assets/ai-sistant.jpg'
import Quran from '../../assets/logo6.png'

import {Swiper, SwiperSlide} from 'swiper/react';
import { Navigation, Pagination} from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const data =[
  {
    avatar: openCV,
    name: 'OpenCV',
    testimony: "(Open Computer Vision) A free online course/project in Python implementing machine learning and computer vision to recognize faces or draw on live camera feed",
    button:"Github",
    link:"https://github.com/demiryo/FacialReconitionAI"
  },
  {
    avatar: Clumi,
    name: 'Clumi Cloud Emulator',
    testimony: "I've contributed to the development and implementation of Clumi, a revolutionary cloud emulator for developers. This powerful tool allows developers to test and debug cloud solutions locally, saving significant costs on subscription fees. Clumi's microservice architecture, use of TypeScript, and cloud-agnostic approach give it a competitive edge. With the rapidly growing cloud computing market, Clumi's pilot project holds immense potential to capture a substantial share. ",
    button:"Learn More",
    link:"https://clumi.co"
  },
  {
    avatar: AI,
    name: 'Job AI-sistant',
    testimony: "Using effective prompt engineering, I have developed Job AI-sistant - a powerful tool designed to optimize your job search process. With this cutting-edge application, generating personalized and fluent cover letters has never been easier. Just provide a template and the job description, and witness as Job AI-sistant crafts the perfect cover letter tailored precisely to your needs. Say farewell to writing struggles and embrace an efficient and effective job hunting experience like never before",
    button:"Source Code",
    link:"https://github.com/demiryo/job-ai-sistant"
  },
  {
    avatar: Quran,
    name: 'Quran Research Website',
    testimony: "I am a full-stack developer in a research website using OJET that uses computer vision to collect a database of the Quran to gather statistics and patterns of the complex linguistics of the Holy Quran. ",
    button:"Visit Site",
    link:"https://hawsabah.org"
  }
]

const Projects = () => {
  return (
    <section id='projects'>
      <h5>My learning and work experience so far</h5>
      <h2>Projects</h2>
      <Swiper className="container testimonials_container"
      slidesPerView={1}
      spaceBetween={30}
      loop={true}
      pagination={{
      clickable: true,
      }}
      navigation={true}
      modules={[Pagination, Navigation]}
      >
        {
          data.map(({avatar, name, testimony, button, link}, index) =>{
            return(
              <SwiperSlide key={index} className='testimonial'>
                <div className='client_avatar'>
                  <img src={avatar} alt="Shehab" />
                  
                </div>
                <h3 className='client_name'>{name}</h3>
                <small className='client_testimony'>
                  {testimony}
                </small>
                 <a href={link} target='_blank' className='btn testimony_button'>{button}</a>  
              </SwiperSlide>
            )
          })
        }
        
      </Swiper>
    </section>
  )
}


export default Projects