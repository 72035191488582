import React from 'react'
import './about.css'
import ME from '../../assets/goggles.jpeg'
import {BsFillAwardFill} from 'react-icons/bs'
import {FaMicroscope} from 'react-icons/fa'
import {BsFillEyeFill} from 'react-icons/bs'
const about = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className='container about_container'>
        <div className='about_me'>

        <div className="about_me-image">
          <img src={ME} alt="About Image" />
        </div>
        </div>
        <div className='about_content'>
          <div className="about_cards">
    
            <article className='about_card'>
              
              <FaMicroscope className=' about_icon ' />
              <h5>Full Stack Development</h5>
              <small>2 years</small>
            </article>

            <article className='about_card'>
              <BsFillEyeFill className=' about_icon '/>
              <h5>ML & Computer vision</h5>
              <small>1 year</small>
            </article>

            <article className='about_card'>
              <BsFillAwardFill className=' about_icon '/>
              <h5>Overall Experience</h5>
              <small>3+ years</small>
            </article>

          </div>

          <p>
          Hi! I'm Omar Demiry, I have a determined ambitious character.
          I am currently a full time student at the University of Washington Bothell, studying computer Science. I have particular interests in AI & machine learning, data science, and cloud computing.
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>

    </section>
  )
}

export default about