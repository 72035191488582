import React from 'react'
import './skills.css'
import {BiSolidCheckShield} from 'react-icons/bi'
import {BsFillLightbulbFill} from 'react-icons/bs'

const Skills = () => {
  return (
    <section id='skills'>
      <h5>what skills I have</h5>
      <h2>Skills and Intrests</h2>
      <div className="container experience_container">


        <div className="experience_frontend">
          <h3>Skills</h3>

          <div className="experience_content">

            

            

            <article className=' experience_details '>
              <BiSolidCheckShield className='experience_icon'/>
              <div>
                <h4>Python</h4>
                <small className='text-light'>3+ years</small>
              </div>
            </article>

            <article className=' experience_details '>
              <BiSolidCheckShield className='experience_icon'/>
              <div>
                <h4>Java</h4>
                <small className='text-light'>3+ years</small>
              </div>
            </article>
            
            <article className=' experience_details '>
              <BiSolidCheckShield className='experience_icon'/>
              <div>
                <h4>C++</h4>
                <small className='text-light'>3+ years</small>
              </div>
            </article>

            <article className=' experience_details '>
              <BiSolidCheckShield className='experience_icon'/>
              <div>
                <h4>C#</h4>
                <small className='text-light'>2+ years</small>
              </div>
            </article>
            
            <article className=' experience_details '>
              <BiSolidCheckShield className='experience_icon'/>
              <div>
                <h4>javascript</h4>
                <small className='text-light'>2+ years</small>
              </div>
            </article>

            <article className=' experience_details '>
              <BiSolidCheckShield className='experience_icon'/>
              <div>
                <h4>HTML</h4>
                <small className='text-light'>1+ years</small>
              </div>
            </article>

            <article className=' experience_details '>
              <BiSolidCheckShield className='experience_icon'/>
              <div>
                <h4>React</h4>
                <small className='text-light'>1 year</small>
              </div>
            </article>

            <article className=' experience_details '>
              <BiSolidCheckShield className='experience_icon'/>
              <div>
                <h4>OJET</h4>
                <small className='text-light'>1 year</small>
              </div>
            </article>

          </div>

        </div>


        <div className="experience_backend">
          <h3>Intrests</h3>

          <div className="experience_content">

              <article className=' experience_details '>
                <BsFillLightbulbFill className='experience_icon'/>
                <div>
                  <h4>AI & ML</h4>
                  <small className='text-light'></small>
                </div>
              </article>

              <article className=' experience_details '>
                <BsFillLightbulbFill className='experience_icon'/>
                <div>
                 <h4>cloud computing</h4>
                 <small className='text-light'></small>
                </div>
              </article>

              <article className=' experience_details '>
                <BsFillLightbulbFill className='experience_icon'/>
                <div>
                  <h4>data science</h4>
                  <small className='text-light'></small>
                </div>
              </article>

              <article className=' experience_details '>
                <BsFillLightbulbFill className='experience_icon'/>
                <div>
                  <h4>Biology</h4>
                  <small className='text-light'></small>
                </div>
              </article>

              <article className=' experience_details '>
                <BsFillLightbulbFill className='experience_icon'/>
                <div>
                  <h4>Mechanical Engineering</h4>
                  <small className='text-light'></small>
                </div>
              </article>

          </div>
        </div>



      </div>
    </section>
  )
}

export default Skills