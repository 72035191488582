import React from 'react'
import './contact.css'
import {MdEmail} from 'react-icons/md'
import {BsWhatsapp} from 'react-icons/bs'
import {BsDiscord} from 'react-icons/bs'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'

const contact = () => {
  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className='container contact_container'>
        <div className='contact_options'>
          <article className="contact_option">
            <MdEmail/>
            <h4>Email</h4>
            <h5>Omar@demiry.com</h5>
            <a href="mailto:Omar@demiry.com" target='_blank'>Send Message</a>
          </article>
          <article className="contact_option">
            <BsLinkedin/>
            <h4>Linkedin</h4>
            <h5>Omar Demiry</h5>
            <a href="https://www.linkedin.com/in/omar-demiry-002b75267/" target='_blank'>Send Message</a>
          </article>
          
          <article className="contact_option">
            <BsDiscord/>
            <h4>Discord</h4>
            <h5>ObiWan117</h5>
            <a href=" https://discordapp.com/users/ObiWan117" target='_blank'>Send Message</a>
          </article>
          <article className="contact_option">
            <BsWhatsapp/>
            <h4>Whatsapp / Phone</h4>
            <h5>(425)-614-6291</h5>
            <a href="https://wa.me/14256146291" target='_blank'>Send Message</a>
          </article>
          <article className="contact_option">
            <MdEmail/>
            <h4>Email (imformal)</h4>
            <h5>demiryo.sc@gmail.com</h5>
            <a href="mailto:demiryo.sc@gmail.com" target='_blank'>Send Message</a>
          </article>


        </div>
        
          <form action="https://formsubmit.co/Omar@demiry.com" method='POST' target='_blank'>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder= 'Your Email' required />
          <textarea name="message" rows="7" placeholder= 'Your Message'required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default contact