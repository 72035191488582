import React from 'react'

import CTA from './CTA'
import photo from '../../assets/self.png'
import Socials from './headerSocials'
import {AiFillDownCircle} from 'react-icons/ai'
import './header.css'

const header = () => {
  return (
    <div>
      <header>
        <div className="container header_container">
          
          <h5>Hello i'm</h5>
          <h1>Omar Demiry</h1>
          <h5 className="text-light">FullStack Developer</h5>
          <CTA />
          <Socials/>
          <a href="#contact" className='scroll_down'><AiFillDownCircle/></a>
          <div className="me">
          <img src={photo} alt="photo" />
          </div>

         
        </div> 
      </header>
    </div>
  )
}

export default header